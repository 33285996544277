form {
	margin: 1rem 0 1rem 0;
	display: grid;
	gap: 20px;
}

@media only screen and (min-width: 960px) {
	form {
		grid-template-columns: 5fr 1fr 1fr;
	}
}

form input {
	padding: 14px;
	border: 3px solid rgb(225, 225, 225);
	border-radius: 10px;
	box-sizing: border-box;
	margin-left: 5px;
	font-size: 20px;
}

form button {
	border: none;
	border-radius: 10px;
	margin-left: 5px;
	font-size: 20px;
	padding: 12px;
}


form button[type='button'] {
	background-color: rgb(225, 225, 225);
}

form button:hover {
	cursor: pointer;
	color: red;
  }

.input{
	width: 500px;
	margin-top: 10px;
	margin-bottom: 5px;
	border-radius: 5px;
}

img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.image-grid {
	display: grid;
	gap: 0.5rem;
	grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
}

.image-preview {
    position: relative;
    width: 500px;
    height: 500px;
    margin: 20px;
}

.image-preview img {
    width: 500px;
    height: 500px;
    object-fit: cover;
}

.footer {
	display: flex;
	justify-content: center;
}

.footer button {
	font-size: 36px;
	border-radius: 10px;
	border: none;
	padding: 18px;
	margin: 3rem 0 3rem 0;
}

